<template>
    <header :class="[style.header, {[style.active]: scrolled200 }]">
        <Container fluid :class="style.headerContainer">
            <LogoScrolled :active="scrolled200" />
            <MainMenu :visible-section="visibleSection" />
        </Container>
    </header>
</template>

<script setup>
    import LogoScrolled from '@/components/Logo/LogoScrolled.vue';
    import MainMenu from '@/components/MainMenu/MainMenu.vue';
    import Container from '@/components/Container/Container.vue';
    import { scrolledYAxis } from '@/helpers/detectScroll.js'

    import style from './Header.module.scss';
    import { defineProps, computed } from 'vue';

    defineProps( {
        visibleSection: {
            type: String,
            required: false
        },
    } );

    const scrolled200 = computed( () => ( scrolledYAxis.value > 200 ) );

</script>
