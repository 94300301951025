<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script setup>
import style from './Container.module.scss';
import { computed, defineProps } from 'vue';

const props = defineProps({
    fluid: {
        type: Boolean,
        default: false
    }
});

const classes = computed( () => (props.fluid) ? style.containerFluid : style.container );


</script>