<template>
    <div :class="style.mobileHeader">
        <component :is="mobileMenu" @clickedOnItem="handleClick" @clickedOvrl="handleClick" :visible-section="visibleSection" />
        <Logo :class="style.logo" />
        <div :class="[style.scrollMenu, {[style.active]: scrolled200 }]">
            <Container :class="style.mobileHeaderContainer">
                <LogoScrolled :active="scrolled200" />
                <Burger @clicked="handleClick" :scrolled="scrolled200" />
            </Container>
        </div> 
    </div>
</template>

<script setup>
import { defineProps, computed, defineAsyncComponent, reactive } from 'vue';
import Container from '@/components/Container/Container.vue';
import LogoScrolled from '@/components/Logo/LogoScrolled.vue';
import Logo from '@/components/Logo/Logo.vue';
import Burger from '@/components/MainMenu/Burger.vue';
import style from './HeaderMobile.module.scss';
import { scrolledYAxis } from '@/helpers/detectScroll.js'

defineProps ( {
    visibleSection: {
        type: String,
        required: true
    }
} );

const activeMenu = reactive({
    value: false
});

const handleClick = () => {
    activeMenu.value = !activeMenu.value;
}

const mobileMenu = computed( () => (activeMenu.value) ? defineAsyncComponent( () => import('@/components/MainMenu/MainMenuMobile.vue') ) : null );
const scrolled200 = computed( () => ( scrolledYAxis.value > 200 ) );

</script>