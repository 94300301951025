<template>
    <div :class="[style.LogoScrolled, {[style.active]: active}]" @click="scrollToTop">
        {{ logoText }}
    </div>
</template>
<script setup>
import style from './LogoScrolled.module.scss';
import { defineProps } from 'vue';

const logoText = "<ChristineKefalianou />";

defineProps({
    active: {
        type: Boolean,
        default: false
    },

});

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

</script>