export const intro = {
    "title": "Introduction",
    "content": "<p>Greetings!</p><p>My name is <strong>Christine Kefalianou</strong>, and I am a dedicated <strong>Full-Stack Engineer</strong> with a degree in Informatics Engineering from A.T.E.I. of Central Greece.</p><p>I enjoy Full-Stack Engineering because it combines both front-end creativity with back-end challenges, allowing me to leverage my technical expertise and creative problem-solving skills.</p><p>Throughout my professional journey, I have developed strong <strong>communication</strong> and <strong>organizational skills</strong>, and I appreciate the value of <strong>collaboration</strong> in team environments.</p>",
};

export const details = {
    "title" : "a little more about me....",
    "list": [
        {
            "title": "Bachelor:",
            "content": "Informatics Engineering A.T.E.I. of Central Greece",
            "degree": "Diploma Degree: 7.43"
        },
        {
            "title": "Thesis:",
            "content": "Image Recognition & Classification using SVM",
            "degree": "Thesis Degree: 10",
            "link": "https://ckefalianou.gr/thesis.pdf",
            "linkText": "Download Thesis"
        },
        {
            "title": "Languages:",
            "content": "English: Pearson PTE-LCCI (C2)</br>German: Goethe-Zertifikat (B1)"
        },
        {
            "title": "Driving Licence",
            "content": "B"
        }
    ]
};