<template>
        <li :class="[style.timelineItem, {[style.active] : isActive}]" @click="sendSkills">
            <div :class="style.timelineItemWrap">
                <h3 class="h4">{{ experience.title }}</h3>    
                <small>{{ experience.company }}</small>    
                <time>{{ experience.startDate }} - {{ experience.endDate }}</time>
                <svg :class="style.svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                    y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                    <polyline points="12.25,5 23.25,16 12.25,27 " />
                </svg>
            </div>
        </li>
</template>
<script setup>
    import style from './ExperienceTimelineItem.module.scss';
    import { defineProps, computed, defineEmits, getCurrentInstance } from 'vue';

    const instance = getCurrentInstance().proxy;

    defineEmits('clicked')

    const props = defineProps({
        experience: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        activeSkillIndex: {
            type: Number,
            required: true
        }
    });

    const isActive = computed( () => props.index === props.activeSkillIndex );

    const sendSkills = () =>  instance.$emit('clicked', props.experience?.skills ?? '' , props.index );
    
</script>
