export const seminars = [
    {
        "title": "Clean Code",
        "company": "Udemy",
        "startDate": "12/2024",
        "endDate": "01/2025",
        "credential": "https://ckefalianou.gr/Christine-Kefalianou-Clean_Code.pdf"
    },
    {
        "title": "Cybersecurity",
        "company": "ACTA SA - Spin Off Aristotle University of Thessaloniki",
        "startDate": "10/2023",
        "endDate": "10/2029",
        "credential": "https://ckefalianou.gr/cybersecurity-ACTA.pdf"
    },
    {
        "title": "Understanding Typescript",
        "company": "Udemy",
        "startDate": "04/2023",
        "endDate": "No Expiration Date",
        "credential": "https://ude.my/UC-dbbc807a-1c4e-4789-b9d1-b4d56f10f347"
    },
    {
        "title": "The Complete JavaScript Course 2023: From Zero to Expert!",
        "company": "Udemy",
        "startDate": "08/2021",
        "endDate": "No Expiration Date",
        "credential": "http://ude.my/UC-7df305aa-cf93-4e09-b11c-1bc58b74d807"
    },
    {
        "title": "Vue Certification",
        "company": "Vue Academy",
        "startDate": "01/2023",
        "endDate": "No Expiration Date",
        "credential": "https://www.ckefalianou.gr/ChristineKefalianou-VueCertification.pdf"
    },
    {
        "title": "The Complete SQL Bootcamp 2021: Go from Zero to Hero",
        "company": "Udemy",
        "startDate": "06/2021",
        "endDate": "No Expiration Date",
        "credential": "https://ude.my/UC-b12271f5-f01f-4625-9b19-9c37969faea9"
    },
    {
        "title": "Certificate of Completion: C# Course",
        "company": "Sololearn",
        "startDate": "10/2016",
        "endDate": "No Expiration Date",
        "credential": "https://www.sololearn.com/Profile/2313954/"
    },
    {
        "title": "Full stack web Development Certification, Computer Software Engineering",
        "company": "freeCodeCamp",
        "startDate": "09/2016",
        "endDate": "No Expiration Date",
    },
    {
        "title": "Grow Greek Tourism Online",
        "company": "Google",
        "startDate": "08/2016",
        "endDate": "No Expiration Date",
        "credential": "https://greektourism.withgoogle.com/"
    },
    {
        "title": "Digital Marketing",
        "company": "Ethnikon kai Kapodistriakon Panepistimion Athinon",
        "startDate": "05/2016",
        "endDate": "No Expiration Date",
    },
];