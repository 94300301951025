import { computed, ref } from 'vue';

const scrollY = ref(null);

window.addEventListener('scroll', function() {
    scrollY.value = this.scrollY;
}, false);

export const scrolledYAxis = computed(
   () => scrollY.value
);