<template>
    <div :class="classes">
        <template v-if="content">
            <h3 v-if="content.title">{{ content.title }}</h3>
            <ul :class="style.list" v-if="content.list && content.list.length > 0">
                <li v-for="item,index in content.list" :key="`intro-item-${index}`">
                    <h4 v-if="item.title">{{ item.title }}</h4>
                    <p v-if="item.content" v-html="item.content"></p>
                    <small v-if="item.degree" >{{ item.degree }}</small>
                    <div>  
                        <a v-if="item.link && item.linkText" target="_blank" :href="item.link">{{ item.linkText }}  </a>
                    </div>
                </li>
            </ul>
        </template>
        <template else>
            <slot />
        </template>
    </div>
</template>
<script setup>
import style from './InfoNote.module.scss';
import { defineProps, computed } from 'vue';

const props = defineProps({
    content: {
        type: Object,
        required: false
    },
    dark: {
        type: Boolean,
        default: false
    }
});

const classes = computed( () => [
    style.infoNote,
    (props.dark) ? style.dark : ''
] );
</script>
