 import { computed, ref } from 'vue';

 const windowInnerWidth = ref(null);
 windowInnerWidth.value = window.innerWidth;

 window.addEventListener('resize', () => {
    windowInnerWidth.value = window.innerWidth;
 })

 export const isSmallScreen = computed(
    () => (windowInnerWidth.value <= 1024 ) ? true : false
 );