<template>
    <ul :class="style.mainMenu">
        <Item  
            @clicked="getActiveElement" 
            :activeAnchor="data.activeMenuItem" 
            v-for="item,index in menu.items" 
            :title="item.title" 
            :anchor="item.anchor" 
            :key="`menuitem-${index}`"
        />
    </ul>
</template>
<script setup>
import menu from './menu.json';
import style from './MainMenu.module.scss';
import Item from './MainMenuItem.vue'
import { reactive, defineProps, watch } from 'vue';

const data = reactive({
    activeMenuItem: ''
});

const props = defineProps( {
    visibleSection: {
        type: String,
        required: false
    }
} );

watch(() => props.visibleSection, () => {
    data.activeMenuItem = props.visibleSection
});

const getActiveElement = (element) => {
    data.activeMenuItem = element;
}
</script>
