<template>
    <li :class="classes" @click="scrollToView(anchor)">{{ title }}</li>
</template>

<script setup>
import { computed, defineProps, defineEmits, getCurrentInstance } from 'vue';
import style from './MainMenu.module.scss';

const instance = getCurrentInstance().proxy;

defineEmits('clicked');

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    anchor: {
        type: String,
        required: true
    },
    activeAnchor : {
        type: String,
        default: ''
    }
});

const scrollToView = () => { 
    const element = document.getElementById(props.anchor);
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    instance.$emit('clicked', props.anchor);
}

const classes = computed( () => [
    style.mainMenuItem,
    (props.anchor == props.activeAnchor) ? style.active : []
]  );
</script>