<template>
    <div :class="style.HeroParticles">
        <vue-particles
            :class="style.particles"
            color="#E6FFFF"
            :particleOpacity="1"
            :particlesNumber="100"
            shapeType="circle"
            :particleSize="4"
            linesColor="#E6FFFF"
            :linesWidth="1"
            :lineLinked="true"
            :lineOpacity="1"
            :linesDistance="150"
            :moveSpeed="3"
            :hoverEffect="true"
            hoverMode="grab"
            :clickEffect="false"
            clickMode="push"
      />
      <Logo :class="style.heroLogo" />
    </div>
</template>
<script>
export default {
    name: 'HeroParticles'
};
</script>
<script setup>
import style from './HeroParticles.module.scss';
import Logo from '@/components/Logo/Logo.vue';
</script>